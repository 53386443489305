import axios from "axios";


export const fetchRequest = async(endpoint) => {
    return await axios.get(endpoint);
};

export const postRequest = (endpoint, body) => {
    return axios.post(endpoint, body);
};

export const patchRequest = (endpoint, body) => {
    return axios.patch(endpoint, body);
};

export const deleteRequest = (endpoint) => {
    return axios.delete(endpoint);
};

