import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from './Redux/store';
import { Provider } from 'react-redux';

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
   <PersistGate loading={null} persistor={persistor} >
      <BrowserRouter>
      <App />
    </BrowserRouter>
    </PersistGate >
  </Provider>
</React.StrictMode>
);


reportWebVitals();
