import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchRequest } from "../Utilities/Request"
export const fetchRecitersList = createAsyncThunk('reciters/list' , async()=>{
    return await fetchRequest("https://www.mp3quran.net/api/v3/reciters?language=eng")
    .then((response)=>{
        return response?.data;
    })
    .catch((error)=>{
        console.log("error get")
        return[];
    })

});
export const surahList = createAsyncThunk("surah/list" , async()=>{
    return await fetchRequest("https://mp3quran.net/api/v3/suwar?language=eng")
    .then((response)=>{
        return response?.data;
    })
    .catch((error)=>{
        console.log("error get")
        return[];
    })
})

const initialState={
    recitersName:{},
    loading:null,
    surah:[],
}
const RecitersName = createSlice({
    name:'recitersName',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase(fetchRecitersList.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(fetchRecitersList.fulfilled , (state,action) =>{
            state.recitersName = action.payload.reciters;
            state.loading = false;
        });
        builder.addCase(fetchRecitersList.rejected , (state)=>{
            state.loading = false;
        });

        builder.addCase(surahList.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(surahList.fulfilled , (state,action) =>{
            state.surah = action.payload.suwar;
            state.loading = false;
        });
        builder.addCase(surahList.rejected , (state)=>{
            state.loading = false;
        });

        
    }
});
export default RecitersName