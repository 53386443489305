import React from 'react'
import OwlCarousel from 'react-owl-carousel';
const AyatSlider = () => {
  return (
    <OwlCarousel
                    className="slider-ayat owl-carousel"
                    margin={10}
                    items={1}
                    autoplay 
                   autoplayTimeout={3000}
                   loop
                    >
                        <div className="ayat item">
                            <img src="assets/img/ayat.png" alt="ayat" />
                            <h4>O you who  have believed, Seek help through patience and prayers.</h4>
                            <h5>Al-Baqrah (2) : 153</h5>
                        </div>
                        <div className="ayat item">
                            <img src="assets/img/ayat.png" alt="ayat" />
                            <h4>O you who  have believed, Seek help through patience and prayers.</h4>
                            <h5>Al-Baqrah (9) : 153</h5>
                        </div>
                        <div className="ayat item">
                            <img src="assets/img/ayat.png" alt="ayat" />
                            <h4>O you who  have believed, Seek help through patience and prayers.</h4>
                            <h5>Al-Baqrah (1) : 153</h5>
                        </div>


                    </OwlCarousel>
  )
}

export default AyatSlider