import React from 'react'
import AyatSlider from './AyatSlider'

const Ayat = () => {
  return (
    <section className="gap islamic-ayat no-bottom">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="heading">
                        <p>Quranic Verses With Translation</p>
                        <h2>Islamic ayat with translation</h2>
                    </div>
                    <AyatSlider />
                    
                </div>
                <div className="col-lg-6">
                    <div className="ayat-img">
                        <img src="assets/img/ayat-with-man.png" alt="img" />
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/img/ayat-with.jpg" className="ayat-with-img" alt="ayat-with" />
    </section>
  )
}

export default Ayat