import React from 'react'

const Progress = () => {
  return (
    <div id="progress">
          <span id="progress-value"><i className="fa-solid fa-up-long"></i></span>
    </div>
  )
}

export default Progress